import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MainService } from 'src/app/provider/main.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  Obj: { 'email': any; };
  showPassOrText: boolean;
  showEyeOrCrossEye: boolean;
  // email: '[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,5}'
  constructor(
    private router: Router,

    private fb: FormBuilder,
    private http: HttpClient,
    public service: MainService
  ) { }

  ngOnInit() {
    this.checkInput()
  }

  checkInput() {
    let remData = JSON.parse(localStorage.getItem('rememberMe')) ? JSON.parse(localStorage.getItem('rememberMe')) : '';
    let pass = ''
    if (remData != '') {
      pass = window.atob(remData.password)
    }

    this.loginForm = this.fb.group({
      // email: [remData.email, Validators.required, Validators.pattern('[a-zA-Z0-9.-]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,5})')],
      email: [remData.email, Validators.required],

      password: [pass, [Validators.required]],
      rememberMe: (false)

    })
  }



  onLogin() {
    this.service.showSpinner();
    this.service.post("auth", {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
      deviceType: "WEB"
    }).subscribe(
      (res: any) => {

        this.service.hideSpinner();
        if (res['status'] == '200') {
          localStorage.setItem('Auth', res['data']['token']);

          if (this.loginForm.value.rememberMe == true) {
            let remData = {
              "email": this.loginForm.value.email,
              "password": window.btoa(this.loginForm.value.password)
            }
            localStorage.setItem('rememberMe', JSON.stringify(remData))

          }
          this.service.changeLoginSub('login');
          window.location.reload()
          this.router.navigate(['dashboard']);
        }
      },
      (err: any) => {

        this.service.hideSpinner();
        if (err['status'] == '401') {
          this.service.toasterErr('Unauthorized Access');
        } else {
          this.service.toasterErr('Something Went Wrong');
        }
      }
    )
    this.Obj = {
      'email': this.loginForm.value.email,

    }

    localStorage.setItem('data', JSON.stringify(this.Obj));

  }
  showPassword() {
    this.showPassOrText = !this.showPassOrText;
    this.showEyeOrCrossEye = !this.showEyeOrCrossEye
  }


}

